import { createSlice } from "@reduxjs/toolkit";
import { editNoKkBersaudara, getAllBersaudara, postPotonganBersaudara } from "../actions/bersaudara";

export const bersaudaraStore = createSlice({
    name: "bersaudara",
    initialState: {
        allBersaudara: [],
        loadingBersaudara: false,
        msgBersaudara: ""
    },
    extraReducers: builder => {
        builder
            .addCase(getAllBersaudara.pending, (state) => {
                state.loadingBersaudara = true
            })
            .addCase(getAllBersaudara.fulfilled, (state, action) => {
                state.loadingBersaudara = false
                state.allBersaudara = action.payload
            })
            .addCase(getAllBersaudara.rejected, (state, action) => {
                state.loadingBersaudara = false
                state.msgBersaudara = action.payload
            })
            .addCase(postPotonganBersaudara.pending, (state) => {
                state.loadingBersaudara = true
            })
            .addCase(postPotonganBersaudara.fulfilled, (state, action) => {
                state.loadingBersaudara = false
                state.msgBersaudara = action.payload.msg
            })
            .addCase(postPotonganBersaudara.rejected, (state, action) => {
                state.loadingBersaudara = false
                state.msgBersaudara = action.payload
            })
            .addCase(editNoKkBersaudara.pending, (state) => {
                state.loadingBersaudara = true
            })
            .addCase(editNoKkBersaudara.fulfilled, (state, action) => {
                state.loadingBersaudara = false
                state.msgBersaudara = action.payload.msg
            })
            .addCase(editNoKkBersaudara.rejected, (state, action) => {
                state.loadingBersaudara = false
                state.msgBersaudara = action.payload
            })
    }
})
export default bersaudaraStore.reducer