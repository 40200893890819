import React, { useEffect, useState } from 'react'
import { GiMoneyStack } from 'react-icons/gi'
import { HiOutlineChevronDown, HiOutlinePencil, HiOutlineTrash } from 'react-icons/hi2'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import DialogDelete from '../component/DialogDelete'
import FormGenerateTagihan from '../component/FormGenerateTagihan'
import FormListSpp from '../component/FormListSpp'
import HeaderUi from '../component/HeaderUi'
import Loader from '../component/Loader'
import SnackBar from '../component/SnackBar'
import Table from '../component/Table'
import { getThnAjar } from '../store/actions/optional'
import { getPembayaran } from '../store/actions/pembayaran'
import { allSantri, destroySantri, oneSantri, searchSantri } from '../store/actions/santri'
import { createTagihan } from '../store/actions/spp'
import { TfiPanel } from "react-icons/tfi"
import { santriActions } from '../store/slice/santri'

function TablesSantri() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [dialogDelete, setDialogDelete] = useState(false)
    const [formSpp, setFormSpp] = useState(false)
    const [dropActions, setDropActions] = useState(false)
    const [formGenerate, setFormGenerate] = useState(false)
    const [data, setData] = useState("")
    const [uuid, setUuid] = useState("")
    const [check, setCheck] = useState(0)
    const [kodeLembaga, setKodeLembaga] = useState("")
    const [snack, setSnack] = useState(false)
    const [tahun, setTahun] = useState({})
    // const [uuid,setUuid] = useState("")
    const { allPembayaran, loadingPembayaran } = useSelector((state) => state.pembayaran)
    const { thn_ajar } = useSelector((state) => state.optional)
    const { msgSpp } = useSelector((state) => state.spp)
    useEffect(() => {
        dispatch(santriActions.clearSantriOne())
        dispatch(santriActions.clearAyah())
        dispatch(santriActions.clearIbu())
        dispatch(allSantri())
    }, [dialogDelete])
    // const hapusSantri = () =>{
    //     dispatch(destroySantri(uuid))
    //     setDialogDelete(!dialogDelete)
    // }

    const redirect = () => {
        navigate("/home/input/santri")
    }
    const { santriByKey, loadingSantri } = useSelector((state) => state.santri)
    const [key, setKey] = useState("")
    useEffect(() => {
        if (key !== "" && key.length > 2) {
            dispatch(searchSantri(key.trim()))
        }
        dispatch(getThnAjar())
    }, [key])
    const addTagihan = (e) => {
        dispatch(createTagihan(e))
        setFormGenerate(!formGenerate)
        setSnack(!snack)
    }
    return (
        <div>
            <Loader show={loadingSantri} />
            <Loader show={loadingPembayaran} />
            <HeaderUi titleHeader="Table Santri" location="List" />
            <SnackBar show={snack} toggle={setSnack} msg={msgSpp} />
            <FormListSpp showForm={formSpp} closeForm={setFormSpp} lembaga={kodeLembaga} />
            <FormGenerateTagihan showForm={formGenerate} closeForm={setFormGenerate} data={data} uuid_santri={uuid} submit={addTagihan} />
            <div className='bg-white m-5 p-5 flex flex-col shadow-lg'>
                <label className='text-md font-light text-slate-700'>Masukkan NUWB/Nama</label>
                <input className='px-2 py-1 outline-none border border-sky-400 w-[20rem] font-light' value={key} onChange={(e) => setKey(e.target.value)} />
            </div>
            <Table
                titleFirst="Tambah Santri"
                clickFirst={redirect}
            >
                <div className='bg-white'>
                    {santriByKey.length === 0 ? <div>
                        <h1 className='font-light text-center my-10 text-xl'>Tidak Ada Santri</h1>
                    </div> :
                        <div className=" overflow-x-auto shadow-md sm:rounded-lg my-7 overflow-y-hidden">
                            <table className="w-full text-sm text-left text-gray-500 ">
                                <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                                    <tr>
                                        <th scope="col" className="px-6 py-3">
                                            No
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            NUWB
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Nama
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Lembaga
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Kelas
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Ruang
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Wali
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Belanja
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Pembayaran
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Panel
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {santriByKey.map((d, id) => (
                                        <tr className="bg-white border-b hover:bg-gray-100" key={id}>
                                            <td className='px-6 py-3'>{id + 1}</td>
                                            <td className='px-6 py-3'>{d.nuwb}</td>
                                            <td className='px-6 py-3'>{d.nama}</td>
                                            <td className='px-6 py-3'>{d.lembaga?.nama}</td>
                                            <td className='px-6 py-3'>{d.kelas}</td>
                                            <td className='px-6 py-3'>{d.ruang}</td>
                                            <td className='px-6 py-3'>{d.wali !== null ? d.wali?.nama : "-"}</td>
                                            <td className='px-6 py-3'><GiMoneyStack className='w-6 h-6 cursor-pointer mx-3' onClick={() => {
                                                navigate(`/home/belanja/santri/${d.nuwb}`)
                                            }} /></td>
                                            <td className='px-10 py-3'><GiMoneyStack className='w-6 h-6 cursor-pointer mx-3' onClick={() => {
                                                navigate(`/home/pembayaran/${d.nuwb}`)
                                            }} /></td>
                                            <td className='px-5  py-3'><TfiPanel className='w-6 h-6 cursor-pointer mx-3' onClick={() => {
                                                navigate(`/home/panel/santri/${d.nuwb}`)
                                            }} /></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
            </Table >
        </div >
    )
}

export default TablesSantri