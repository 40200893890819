import axios from "axios";
import { islogout } from "../store/actions/auth";

const api  = axios.create({
    // baseURL:"http://192.168.1.7:9999"
    // baseURL:"http://localhost:9999"
    // baseURL:"https://api-cakepin-sumbawa.ponpesabuhurairah.id"
    baseURL:"https://api-cakepin-sembalun.ponpesabuhurairah.id"
    // baseURL:"https://sipahamv2.ponpesabuhurairah.id"
    // baseURL:"http://192.168.1.20:9999"
})

let store;
export const injectStore = (_store) => {
  store = _store
}

api.interceptors.request.use((req) => {
    if (!req.url.includes("/login")) {
      req.headers = {
        Authorization: `Bearer ${store.getState().auth.token}`,
      };
    }
  return req;
});

api.interceptors.response.use(
  (res) => {
    return res;
  },
  (err) => {
    if (err.response.status === 401 && store.getState().auth.token !== "") {
     store.dispatch(islogout())
    }
    return Promise.reject(err);
  }
);


export default api